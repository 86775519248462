<template>
  <div class="position-relative">
    <div v-if="canEdit" class="edit-icon-settings d-flex align-items-center">
      <feather-icon
        v-if="!isEditable && !selectedCustomFieldsForFilter"
        v-b-tooltip.hover.bottom
        icon="Edit2Icon"
        :title="$t('form-create-item.edit')"
        size="20"
        class="text-primary zindex-1"
        :class="
          !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="openEdit"
      />
      <feather-icon
        v-if="isEditable"
        icon="XIcon"
        size="20"
        class="text-primary zindex-1 mr-1"
        :class="
          !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="closeEdit"
      />
      <feather-icon
        v-if="isEditable"
        v-b-tooltip.hover.bottom
        icon="SaveIcon"
        :title="$t('header.action.save-layout')"
        size="20"
        class="text-primary zindex-1"
        :class="
          !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="handleEditOrder"
      />
    </div>
    <b-card style="min-height: 70vh ">
      <div class="d-flex mt-50 justify-content-between mx-2">
        <div class="d-flex align-items-center float-left">
          <!-- <a class=" mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a> -->
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t(`${itemType}.title`) }}
            <span
              v-if="showTypes"
              class="dot"
            > • </span>
            <span
              v-if="showTypes"
              class="gray"
            >{{ translate(selectedFilter.name) }}</span>
          </h3>
        </div>
        <div class="d-flex align-items-center">
          <v-select
            v-if="showTypes"
            v-model="selectedFilter"
            class="pr-1 filter-select d-inline-block"
            :clearable="false"
            :searchable="false"
            label="name"
            :options="filterOptions"
            @input="changeSelector"
          />
          <widget-actions
            class="d-flex justify-content-end float-none mb-25"
            :item-type="itemType"
            :type-name="itemType === 'challenges' ? selectedFilter.name : null"
            :show-add="canAdd"
            :show-list-type="true"
            :show-list="true"
            :show-filter="showfilter"
            :is-search-needed="true"
            :show-maximize="false"
            :type="itemType === 'challenges' ? selectedFilter.key === 0 ? null : `${selectedFilter.key}`:null"
            :show-download="itemType === 'projects' && isStaff"
            :is-list-type-card="isListTypeCard"
            @openDownloadModal="downloadSpaces"
            @updateSearch="searchItems"
            @toggleFilters="toggleFilters"
            @toggleListType="toggleListType"
          />
        </div>
      </div>
      <template>
        <div
          v-if="areFiltersVisible"
          class="flex-column flex-md-row justify-content-center filters-container mb-2 px-50 pt-1"
        >
          <!-- Classifiers -->
          <div
            class="d-flex flex-column flex-md-row filters-container mb-2 px-50 pt-1"
          >
            <b-form-group
              :label="$t('filterType')"
              label-for="classifier-filter"
              class="mr-1 w-100"
            >
              <v-select
                v-model="selectedClassifiersForFilter"
                name="classifier-filter"
                clearable
                label="name"
                :reduce="(a) => a"
                :options="finalOptions"
                @input="setSeletedFilter"
              />
            </b-form-group>
            <!-- End Classifiers -->

            <!-- Custom -->
            <b-form-group
              v-if="nextFilter != null"
              label="Value"
              label-for="custom-filter"
              class="mr-1 w-100"
            >
              <v-select
                v-if="nextFilter[0] != null"
                v-model="selectedCustomFieldsForFilter"
                name="custom-filter"
                clearable
                label="name"
                :reduce="(nextFilter) => nextFilter"
                :options="nextFilter"
                @input="setSeletedFinalFilter"
              />

              <b-form-input
                v-if="nextFilter.type === 2"
                v-model="customFieldToSend[nextFilter.key]"
                placeholder="Start typing..."
                class="w-100"
                @keyup="handleChanger"
                @input="
                  setSeletedFinalFilter({
                    text: $event,
                    type: 2,
                    key: nextFilter.key,
                  })
                "
              />
            </b-form-group>
            <!-- End Custom -->
          </div>
        </div>
      </template>
      
      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items && items.length > 0" class="cards-container">
        <div class="d-flex flex-wrap justify-content-around w-100">
          <draggable
            v-if="isEditable"
            v-model="items"
            :list="items"
            :move="checkMove"
            class="w-100"
            @start="dragging = true"
            @end="dragging = false"
          >
            <transition-group class="d-flex flex-wrap justify-content-around w-100">
              <!-- Items Loop -->
              <div
                v-for="item in items"
                v-show="isListTypeCard"
                :key="item.key"
                class="x-scroll-element mx-1 mb-3 p-0"
              >
                <project-card 
                  v-if="item.isPublished || (!item.isPublished && canAdd)" 
                  :is-draggable="isEditable" 
                  :item="item" 
                />
              </div>
            </transition-group>
          </draggable>
          <div
            v-for="item in items"
            v-show="isListTypeCard && !isEditable"
            :key="item.key"
            class="x-scroll-element mt-2 mx-1 mb-3 p-0"
          >
            <project-card
              v-if="item.isPublished || (!item.isPublished && canAdd)"
              :item="item"
              :item-type-single="itemTypeSingle"
              :item-type="itemType"
            />
          </div>
          <project-table
            v-if="!isListTypeCard && !isEditable"
            class="mt-3 px-2"
            :items="items"
            :item-type-single="itemTypeSingle"
            :item-type="itemType"
          />
        </div>
        <!-- Load More items -->
        <div
          v-if="items.length < itemsData.meta.total"
          class="w-100 float-left"
        >
          <b-button
            v-if="!isLoadingNextPage"
            class="center-x my-3"
            variant="outline-primary"
            @click="handleLoadOfNewItems"
          >
            {{ $t("action.load-more") }}
          </b-button>
          <div v-else class="w-100">
            <b-spinner
              class="center-x my-3"
              small
              variant="primary"
              type="grow"
              label="Loading..."
            />
          </div>
        </div>
      </div>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="projectPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: "items" }) }}
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Create Challenge -->
    <b-modal
      v-if="itemType === 'challenges'"
      :id="`modal-create-challenges-${translate(selectedFilter.name)}`"
      v-model="isModalVisible"
      centered
      hide-footer
      size="lg"
      @show="getChallengeTypes"
    >
      <template #modal-header>
        <language-selector-header :title="$t('challenges.modal-create-title')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
      </template>
      <div v-if="isLoadingTypes">
        <b-spinner variant="primary" class="mx-auto my-3 d-block" />
      </div>
      <membership-event-create-modal
        v-if="(!hasTypes || (hasTypes && typeOptions.length > 0)) && !isLoadingTypes"
        item-type="challenges"
        :selected="selectedLanguage"
        :type-options="typeOptions.length > 0 ? typeOptions:[]"
        :type-selected="selectedFilter.id"
        @close-modal="isModalVisible = false"
      />
    </b-modal>
    <b-modal
      v-else
      :id="`modal-create-${itemType}`"
      v-model="isModalVisible"
      centered
      hide-footer
      size="lg"
    >
      <template #modal-header>
        <language-selector-header :title="$t(`${itemType}.modal-create-title`)" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
      </template>
      <membership-event-create-modal
        :item-type="itemType"
        :selected="selectedLanguage"
        @close-modal="isModalVisible = false"
      />
    </b-modal>
    <loading-modal v-model="isDownloading" v-ripple.400="'rgba(234, 84, 85, 0.15)'" />
  </div>
</template>

<script>
// import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import ProjectPlaceholder from '@/assets/images/placeholders/light/projects.svg';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { AppIDLUT, MainType } from '@copernicsw/community-common';
import MembershipEventCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import draggable from 'vuedraggable';
import ProjectCard from '@/views/apps/projects/components/ProjectCard.vue';
import ProjectTable from '@/views/apps/projects/components/ProjectTable.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import LoadingModal from '@core/components/modal/LoadingModal.vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'SpacesList',
  components: {
    MembershipEventCreateModal,
    WidgetActions,
    ProjectCard,
    ProjectTable,
    draggable,
    LanguageSelectorHeader,
    LoadingModal,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    itemType: {
      type: String,
      required: true,
    },
    itemTypeSingle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      isModalVisible: false,
      isListTypeCard: true,
      searchInput: '',
      results: [],
      isEditable: false,
      dragging: false,
      actualItems: [],
      isSending: false,
      firstItems: [],
      selectedFilter: { name: this.$t('events.list.filter.all'), key: 0 },
      selectedLanguage: this.currentLocale,
      isDownloading: false,
      typeOptions: [],
      isLoadingTypes: false,
      showfilter: false,
      areFiltersVisible: false,
      nextFilter: null,
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      customFieldToSend: {},
      selectedCustomFieldsForFilter: null,


    };
  },
  computed: {
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    // Load data from store
    itemsData() {
      return this.typeKey != null ? this.$store.getters.communitiesOthers[this.typeKey] : this.$store.getters.communitiesOthers[this.itemType];
    },
    items() {
      if (this.itemsData) {
        const itemsToIterate = this.searchInput ? this.results : this.itemsData.unpaginated;
        return itemsToIterate;
      }
      return [];
    },
    hasTypes() {
      const showTypes = this.app?.customization?.displayOptions;
      return showTypes > 1;
    },
    showTypes() {
      if (this.itemType === 'challenges') {
        return this.hasTypes;
      }
      return false;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    projectPlaceholder() {
      return ProjectPlaceholder;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    filterOptions() {
      const typesOptions = [{ name: this.$t('events.list.filter.all'), key: 0 }];
      const types = this.$store.getters.types?.challenges?.unpaginated || [];
      types.forEach(type => {
        if (type.key !== 0) {
          typesOptions.push({ name: this.translate(type.namePlural), key: type.key, id: type.id });
        }
      });
      return typesOptions;
    },
    typeKey() {
      return this.$route.query.display;
    },
    displayOption() {
      if (this.typeKey) {
        return this.filterOptions.find((option) => option.key === this.typeKey);
      }
      return this.filterOptions[0];
    },
    canAdd() {
      return checkPermissions('create', this.itemTypeSingle, this.loggedMemberRoles, this.collective);
    },
    canEdit() {
      return checkPermissions(
        'update',
        this.itemTypeSingle,
        this.loggedMemberRoles,
        this.collective,
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    appId() {
      if (this.itemType === 'challenges') {
        return 24;
      }
      return 1;
    },
    apps() {
      return this.$store.getters.apps;
    },
    app() {
      if (this.apps) {
        return this.apps.apps[this.appId] || {};
      }
      return {};
    },
    appTitle() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.app?.name);
    },
    // Filtros computed
    classifiers() {
      if (this.$store.getters.classifiers[this.itemType]) {
        return this.$store.getters.classifiers[this.itemType].unpaginated;
      }
      return [];
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      for (const item of this.classifiers) {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ].push(item);
        }
      }
      const keys = Object.keys(newObject);
      const finalKeys = [];
      for (const row of keys) {
        finalKeys.push({
          key: row,
          name: row,
          type: 1,
        });
      }
      return [newObject, finalKeys];
    },
    finalOptions() {
      return [].concat(
        this.sortedCommunityClassifiers[1],
        this.customFieldsOptions,
      );
    },
    // Final filtros computed
  },
  watch: {
    typeKey(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isLoading = true;
        this.selectedFilter = this.displayOption;
        this.fetchData(true);
        this.isLoading = false;
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    await this.fetchData(true);
    if (this.itemType === 'challenges') {
      this.selectedFilter = this.displayOption;
    }
    await this.fetchClassifiers();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async getChallengeTypes() {
      this.isLoadingTypes = true;
      const response = await this.$store.dispatch('getItems', {
        itemType: 'types',
        storedKey: 'challenges',
        forceAPICall: true,
        page: 1,
        perPage: 200,
        requestConfig: {
          modelType: 'challenge',
          getCommunityTypes: 1,
          selectAll: 1,
        },
      });
      if (response) {
        this.typeOptions = [];
        if (response.data.length > 0) {
          for (const typeOption of response.data) {
            Object.values(typeOption.name).length > 0
              ? this.typeOptions.push({
                code: typeOption.id,
                type: this.translate(typeOption.name),
              })
              : '';
          }
        } else {
          this.typeOptions.push({
            code: null,
            type: 'No hi ha cap tipus creat',
          });
        }
      }
      this.isLoadingTypes = false;
    },
    async downloadSpaces() {
      this.isDownloading = true;
      let response = '';
      response = await this.$store.dispatch('exportFiles', {
        itemType: 'spaces/export',
        mainTypeID: MainType.Project,
      });
      setTimeout(this.csv(response), 3000);
      this.isDownloading = false;
    },
    csv(response) {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = response.data.file;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Spaces.csv';
      hiddenElement.click();
    },
    // Call store action (with dispach) to load data from backend
    closeModal() {
      if (this.itemType === 'challenges') {
        this.$bvModal.hide(`modal-create-${this.itemType}-${this.selectedFilter.name}`);
      }
      this.$bvModal.hide(`modal-create-${this.itemType}`);
    },
    changeSelector() {
      if (this.selectedFilter.key !== 0) {
        this.$router.push({ name: 'challenges', query: { display: this.selectedFilter.key } });
      } else {
        this.$router.push({ name: 'challenges' });
      }
      this.lastLoadedPage = 1;
    },
    async fetchData(force = false, searchString = '') {
      this.isLoading = true;

      const requestConfig = {
        orderByDate: -1,
        checkStatus: true,
        communityParentSlug: this.$store.getters.currentCollective.slug,
        searchString,
        classifiers: this.finalSelectClassifier,
        customFields:
          this.customFieldToSend != null &&
          Object.keys(this.customFieldToSend).length > 0
            ? this.customFieldToSend
            : null,
      };
      if (this.itemType === 'projects') {
        requestConfig.isProject = true;
      }
      if (this.itemType === 'challenges') {
        requestConfig.isChallenge = true;
       // requestConfig.displayOptions = this.typeKey;
        requestConfig.typeKey = this.typeKey != null ? this.typeKey : undefined;
      }
      if (this.itemType === 'organizations') {
        requestConfig.isOrganization = true;
      }
      await this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        customName: 'communitiesOthers',
        storedKey: this.typeKey != null ? this.typeKey : this.itemType,
        forceAPICall: force,
        page: this.lastLoadedPage,
        perPage: 30,
        requestConfig,
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },

    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items?.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    toggleListType() {
      this.isListTypeCard = !this.isListTypeCard;
      return this.isListTypeCard;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t(`${this.itemType}.title`),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async searchItems(value) {
      await this.fetchData(true, value);
    },
    /* Ordenar cursos */
    async checkMove(event) {
      this.actualItems = [];
      this.actualItems = [...this.items];
      const oldIndex = event.draggedContext.index;
      const newIndex = event.draggedContext.futureIndex;
      this.actualItems.splice(oldIndex, 1);
      this.actualItems.splice(newIndex, 0, event.draggedContext.element);
    },
    mainTypeID() {
      switch (this.itemType) {
        case 'projects':
          return 3;
        case 'challenges':
          return 10;
        default: return 1;
      }
    },
    async handleEditOrder() {
      this.isSending = true;
      for (const [index, item] of this.actualItems.entries()) {
        item.order = index + 1;
      }
      await this.$store.dispatch('editItem', {
        noSet: true,
        item: {
          itemType: '/communities/simply/order',
          requestConfig: {
            communities: this.actualItems,
            mainTypeID: this.mainTypeID,
          },
        },
      });
      this.firstItems = [];
      this.firstItems = [...this.actualItems];
      this.isEditable = false;
      this.isSending = false;
    },
    openEdit() {
      this.firstItems = [];
      this.firstItems = [...this.items];
      this.isListTypeCard = true;
      this.isEditable = true;
    },
    closeEdit() {
      this.$store.commit('SET_ITEMS', {
        type: 'communitiesOthers',
        storedKey: this.itemType,
        forceAPICall: true,
        page: 1,
        data: {
          data: this.firstItems,
          meta: {
            current_page: 1,
            total: this.firstItems.length,
          },
        },
      });
      this.isEditable = false;
    },
    /* Fi ordenar cursos */

    // Filtros methods
    canFilter() {
      if (this.classifiers.length > 0) {
        this.showfilter = true;
      } else {
        this.showfilter = false;
      }
    },
    toggleFilters() {
      this.areFiltersVisible = !this.areFiltersVisible;
      if (!this.areFiltersVisible) {
        this.customFieldToSend = null;
        (this.selectedClassifiersForFilter = null),
        (this.finalSelectClassifier = []);
        (this.selectedCustomFieldsForFilter = null), (this.nextFilter = null);
        (this.total = 0),
        (this.filtersIndex = 0),
        (this.filters = []),
        (this.parentClassifiers = []),
        (this.customFieldsInputs = []);
        this.index = 0;
        this.fetchData(true);
      }
    },
    handleChanger() {
      this.fetchData(true);
    },
    async setSeletedFinalFilter(selected) {
      if (selected && selected.type === 2) {
        this.customFieldToSend[selected.key] = selected.text;
      } else if (selected) {
        this.finalSelectClassifier = selected.key;
      } else {
        this.finalSelectClassifier = [];
      }
      this.handleChanger();
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        itemType: 'backoffice/classifiers',
        storedKey: this.itemType,
        customName: 'classifiers',
        page: 1,
        requestConfig: {
          modelType: this.itemType,
          count: 1000,
        },
      });
      this.canFilter();
      this.isLoading = false;
    },
    async setSeletedFilter(selected) {
      this.CustomFieldToSend = null;
      this.finalSelectClassifier = null;
      this.selectedCustomFieldsForFilter = null;
      this.nextFilter = null;
      if (selected.length === 0) {
        this.finalSelectClassifier = null;
      } else {
        let translated = [];
        if (selected.type === 1) {
          for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
            translated.push({
              name: translateTranslationTable(
                this.$store.state.locale.currentLocale,
                row.name,
              ),
              key: row.key,
              type: 1,
            });
          }
        } else if (selected.type === 2) {
          this.customFieldToSend[selected.key] = '';
          translated = {
            type: 2,
            key: selected.key,
          };
        }
        this.nextFilter = translated;
      }
    },
    // Final filtros methods
  },

};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";

.edit-icon-settings {
  position: absolute;
  right: 0px;
  top: -30px;
  cursor: pointer;
}
.dot {
  color: #48a2a9;
  margin: 0 5px;
}
.gray {
  color: #82818d;
  font-size: 16px;
}
.filter-select {
  min-width: 150px;
  @include media-breakpoint-up(md) {
    min-width: 250px;
  }
}
</style>
